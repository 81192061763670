<script setup>
    // Import components
    import PrimevuePortal from 'primevue/portal';
    import { OfferStatus, OfferAccess } from '~/enum';

    // Initialize i18n
    const localePath = useLocalePath();
    const viewport = useViewport();

    // Props definition
    const props = defineProps({
        // Offer to display in the card
        offer: {
            type: Object,
            required: true,
        },
        // Should display the offer details
        showDetails: {
            type: Boolean,
            default: false,
        },
        // Should display the last user bid amount
        showUserBid: {
            type: Boolean,
            default: false,
        },
        // Url of the offers list
        slug: {
            type: String,
            default: '',
        },
        // Name of the offers list
        name: {
            type: String,
            default: '',
        },
        // Is the offer shaking
        shaking: {
            type: Boolean,
            default: false,
        },
        // Show bid buttons
        inline: {
            type: Boolean,
            default: false,
        },
        path: {
            type: String,
            default: null,
        },
    });

    const path = computed(() => {
        return (
            props.path ||
            localePath({
                name: 'marketplace-detail-id',
                params: { id: props.offer.id },
            })
        );
    });

    const inlineButtonsDiv = computed(() => {
        return viewport.isGreaterThan('xs') ? 'card__header' : 'card__footer';
    });

    const emit = defineEmits(['bid']);

    const storeOffersList = () => {
        // Save the offers list in the local storage
        localStorage.setItem(
            `offersList.${props.offer.id}`,
            JSON.stringify({
                slug: props.slug,
                name: props.name,
                index: props.offer.index,
            })
        );
    };

    /**
     * Est-ce que l'offre est vérouillée ?
     * @returns {Boolean}
     */
    const isLocked = computed(() => {
        if (!props.offer.unlocking_bid_at) {
            return false;
        }

        return (
            useState('currentTime').value < new Date(props.offer.unlocking_bid_at).getTime() / 1000
        );
    });

    /**
     * Nombre de secondes restantes avant la fin de l'enchère
     * @returns {Number}
     */
    const endingBidIn = computed(() => {
        return new Date(props.offer.ending_bid_at).getTime() / 1000 - useState('currentTime').value;
    });
</script>

<template>
    <Card
        :id="`offer__card__${offer.id}`"
        class="offer"
        :class="{
            winning: offer.winning === true && showDetails,
            losing: offer.winning === false && showDetails,
            shaking: shaking,
            offer__inline: inline,
        }"
        :url="showDetails ? path : null"
        @click="storeOffersList"
    >
        <!-- Corporation illustration -->
        <template #header>
            <div class="offer__corporation">
                <NuxtImg
                    v-if="offer.corporation.illustration"
                    :src="offer.corporation.illustration"
                    :alt="`Logo ${$t('Seller')}`"
                    loading="lazy"
                />
            </div>

            <!-- Offer main illustration -->
            <div class="offer__img">
                <NuxtImg
                    v-if="offer.illustration"
                    :src="offer.illustration"
                    alt="Offer illustration"
                    loading="lazy"
                    style="object-fit: contain"
                />

                <template v-for="badge in offer.badges">
                    <LazyMarketplaceBadge
                        v-if="badge?.img && badge?.alt"
                        :img="badge.img"
                        :alt="badge.alt"
                    />
                </template>

                <LazyMarketplaceBanner v-if="offer.banner" :banner="offer.banner" />

                <div v-if="props.showDetails && isLocked" class="offer__available_soon">
                    <span v-for="i in 3">{{ $t('Available soon') }}</span>
                </div>
            </div>

            <!-- Bid buttons -->
            <PrimevuePortal
                v-if="inline"
                :appendTo="`#offer__card__${offer.id} .${inlineButtonsDiv}`"
            >
                <div class="offer__buttons">
                    <PrimevueButton
                        v-if="endingBidIn > 0"
                        severity="success"
                        :label="$t('Bid on')"
                        @click="emit('bid', offer)"
                    />

                    <NuxtLink
                        v-if="offer.won"
                        :to="path"
                        class="flex flex-1"
                        v-tooltip.bottom="
                            !offer.order_validated
                                ? $t('Bid in the process of being validated')
                                : ''
                        "
                    >
                        <PrimevueButton
                            :label="$t('Go to order')"
                            class="flex-1"
                            :disabled="!offer.order_validated"
                        />
                    </NuxtLink>
                </div>
            </PrimevuePortal>
        </template>

        <!-- Offer description -->
        <template v-if="showDetails" #body>
            <div class="offer__description">
                <h5>{{ offer.title_card }}</h5>

                <!-- Offer id -->
                <div class="offer__description__auction">
                    <template v-if="offer.access === OfferAccess.PRIVATE">
                        <Icon name="material-symbols:lock" class="text-orange-500" />
                        {{ $t('Closed auction #{id}', { id: offer.id }) }}
                    </template>
                    <template v-else-if="offer.is_bid">
                        <Icon name="ri:auction-fill" />
                        {{ $t('Auction #{id}', { id: offer.id }) }}
                    </template>

                    <template v-else>
                        <Icon name="material-symbols:add-shopping-cart-outline-rounded" />
                        {{ $t('Immediate purchase') + ` #${offer.id}` }}
                    </template>
                </div>

                <!-- Offer quality -->
                <PrimevueTag
                    class="offer__description__quality"
                    :value="offer.quality_name"
                    rounded
                />

                <div v-if="offer.is_bid" class="offer__description__rows">
                    <!-- User bid -->
                    <div
                        v-if="offer.userBid && props.showUserBid"
                        class="offer__description__rows__row"
                    >
                        <span class="offer__label">
                            {{ $t('Your bid') }} ({{ $t(`${offer.userBid.type} bid`) }})
                        </span>
                        <span class="offer__value">{{ asMoney(offer.userBid.amount) }}</span>
                    </div>

                    <!-- Last bid amount -->
                    <div v-if="offer.last_bid_amount" class="offer__description__rows__row">
                        <span class="offer__label">
                            {{ $t(offer.count_bids ? 'Last bid' : 'Starting price') }}
                        </span>
                        <span class="offer__value">{{ asMoney(offer.last_bid_amount) }}</span>
                    </div>

                    <!-- Number of bids -->
                    <div v-if="offer.count_bids" class="offer__description__rows__row">
                        <span class="offer__label">{{ $t('Number of bids') }}</span>
                        <span class="offer__description__flames">
                            <span>{{ offer.count_bids }}</span>
                            <Icon v-if="offer.count_bids > 2" name="icon-park-solid:fire" />
                            <Icon v-if="offer.count_bids > 5" name="icon-park-solid:fire" />
                        </span>
                    </div>

                    <!-- Date de disponibilité de l'offre -->
                    <div v-if="isLocked" class="offer__description__rows__row">
                        <span class="offer__label">{{ $t('Starting bid at') }}</span>
                        <span class="offer__value" style="color: var(--available-soon-date-color)">
                            {{ asDate(props.offer.unlocking_bid_at) }}
                        </span>
                    </div>

                    <!-- Timer till the end of the bid -->
                    <div class="offer__description__rows__row">
                        <span class="offer__label">{{ $t('End of the auction') }}</span>
                        <span class="offer__value">
                            <Countdown
                                :modelValue="endingBidIn"
                                :endingAt="offer.ending_bid_at"
                                :displayDate="offer.status === OfferStatus.SOLD"
                            />
                        </span>
                    </div>
                </div>

                <div v-else class="offer__description__rows">
                    <!-- Immediate purchase amount -->
                    <div class="offer__description__rows__row">
                        <span class="offer__label">{{ $t('Immediate purchase') }}</span>
                        <span class="offer__value">{{ asMoney(offer.last_bid_amount) }}</span>
                    </div>

                    <div class="offer__description__rows__row mt-2">
                        <div class="offer__message">📢 {{ $t('Immediate purchase') }}</div>
                    </div>
                </div>
            </div>
        </template>

        <!-- Offer footer with prices and button -->
        <template v-if="showDetails" #footer>
            <div v-if="!props.inline" class="offer__footer">
                <!-- Total RRP -->
                <div class="offer__footer__prices">
                    <div v-if="offer.total_rrp">
                        <span>{{ asMoney(offer.total_rrp) }}</span>
                        <span class="offer__label">{{ $t('RRP') }}</span>
                    </div>

                    <!-- Offer products quantity -->
                    <div v-if="offer.remaining_quantity">
                        <span>{{ offer.remaining_quantity }}</span>
                        <span class="offer__label">{{ $t('Units') }}</span>
                    </div>

                    <!-- Offer cost per unit -->
                    <div v-if="offer.cost_per_unit">
                        <span>{{ asMoney(offer.cost_per_unit) }}</span>
                        <span class="offer__label">{{ $t('Cost/unit') }}</span>
                    </div>
                </div>

                <!-- GoTo offer button -->
                <div class="offer__button">
                    <PrimevueButton
                        :label="$t('View the offer')"
                        icon="material-symbols:arrow-right-alt-rounded"
                        severity="success"
                        iconPos="right"
                    />
                </div>
            </div>
        </template>
    </Card>
</template>
