<script setup>
    /**
     * Countdown component showing only the number of days
     * if it's greater than 2 days, else display dd hh mm ss
     */

    // Props definition
    const props = defineProps({
        // Icon to display
        icon: {
            type: String,
            default: null,
        },
        // Number of seconds to start the countdown
        modelValue: {
            type: Number,
            default: null,
        },
        // Text to display serverside
        endingAt: {
            type: String,
            default: null,
        },
        // Should we only display the date ?
        displayDate: {
            type: Boolean,
            default: false,
        }
    });

    const emit = defineEmits(['update:modelValue']);

    /**
     * Cast the remaining time to an object with days, hours, minutes and seconds
     * @returns {Object}
     */
    const remaining = computed(() => {
        const time = props.modelValue;

        return {
            seconds: (time % 60).toString().padStart(2, '0'),
            minutes: Math.floor((time % (60 * 60)) / (60)).toString().padStart(2, '0'),
            hours: Math.floor((time % (60 * 60 * 24)) / (60 * 60)).toString().padStart(2, '0'),
            days: Math.floor(time / (60 * 60 * 24)),
        }
    });
</script>

<template>
    <div class="contents">
        <!-- If timer ended, just show finished -->
        <span v-if="props.modelValue <= 0">
            <template v-if="props.displayDate">
                {{ asDate(props.endingAt, true) }}
            </template>

            <template v-else>
                <Icon
                    v-if="icon"
                    class="countdown__icon"
                    :name="icon"
                />

                {{ $t('Done') }}
            </template>
        </span>

        <!-- If more than 2 days remaining, only show days number -->
        <span v-else-if="remaining.days >= 2">
            {{ $t('In {days} days', {days: remaining.days}) }}
        </span>

        <!-- Else show all the countdown -->
        <span v-else class="countdown" :class="{important: remaining.days < 1}">
            <Icon
                v-if="icon"
                class="countdown__icon"
                :name="icon"
            />

            <!-- Show days if remainingTime is more than one day -->
            <span v-if="props.modelValue >= 60 * 60 * 24">
                {{ $t('{days}d', {days: remaining.days}) }}
            </span>

            <!-- Show hours if remainingTime is more than one hour -->
            <span v-if="props.modelValue >= 60 * 60">
                {{ $t('{hours}h', {hours: remaining.hours}) }}
            </span>

            <!-- Show minutes if remainingTime is more than one minute -->
            <span v-if="props.modelValue >= 60">
                {{ $t('{minutes}min', {minutes: remaining.minutes}) }}
            </span>

            <!-- Show seconds if remainingTime is more than one second -->
            <span v-if="props.modelValue">
                {{ $t('{seconds}s', {seconds: remaining.seconds}) }}
            </span>
        </span>
    </div>
</template>

<style lang="scss" scoped>
    .countdown {
        display: flex;
        align-items: center;
        gap: .25rem;
        color: var(--primary-color);

        &.important {
            color: var(--red-600);
        }
    }
</style>
